import { MetaSEO } from "src/components";
import EditablePostForm from "../components/EditablePostForm/EditablePostForm";

function AddPost() {
  return (
    <>
      <MetaSEO title="Add Post" description="Add a new post" />
      <EditablePostForm />
    </>
  );
}

export default AddPost;
